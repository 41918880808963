@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/Gilroy/Gilroy-Light.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/Gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Private";
  font-style: normal;
  font-weight: 700;
  src: url("/public/fonts/Gilroy/Gilroy-ExtraBold.ttf");
}

:root {
  --transition: cubic-bezier(0.86, 0, 0.07, 1);
}

* {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  max-width: 2560px;
  margin: 0 auto;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(162, 162, 167);
  border-radius: 100px;
}

/* animations */

.open-nav {
  animation: open-nav 0.2s cubic-bezier(0.69, 0, 0.42, 0.99) forwards;
  pointer-events: all;
}

.close-nav {
  animation: close-nav 0.2s cubic-bezier(0.69, 0, 0.42, 0.99) forwards;
  pointer-events: none;
}

[data-sal|="header"] {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: opacity 0.2s var(--transition),
    -webkit-transform 0.5s var(--transition);
  transition: opacity 0.2s var(--transition),
    -webkit-transform 0.5s var(--transition);
  -o-transition: transform 0.5s var(--transition),
    opacity 0.2s var(--transition);
  transition: transform 0.5s var(--transition), opacity 0.2s var(--transition);
  transition: transform 0.5s var(--transition), opacity 0.2s var(--transition),
    -webkit-transform 0.5s var(--transition);
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s;
}

[data-sal|="header"].sal-animate {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

[data-sal|="header"] .headline {
  opacity: 0;
  -webkit-transform: rotateX(60deg) rotateY(8deg) translateZ(12px)
    translateY(100px);
  transform: rotateX(60deg) rotateY(8deg) translateZ(12px) translateY(100px);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: opacity 0.6s var(--transition),
    -webkit-transform 0.6s var(--transition);
  transition: opacity 0.6s var(--transition),
    -webkit-transform 0.6s var(--transition);
  -o-transition: opacity 0.6s var(--transition),
    transform 0.6s var(--transition);
  transition: opacity 0.6s var(--transition), transform 0.6s var(--transition);
  transition: opacity 0.6s var(--transition), transform 0.6s var(--transition),
    -webkit-transform 0.6s var(--transition);
  -webkit-transition-delay: 0.24s;
  -o-transition-delay: 0.24s;
  transition-delay: 0.24s;
}

[data-sal|="header"].sal-animate .headline {
  -webkit-transform: rotateX(0) rotateY(0) translateZ(12px) translateY(0);
  transform: rotateX(0) rotateY(0) translateZ(12px) translateY(0);
  opacity: 1;
}

[data-sal|="header"] .subhead {
  -webkit-transform: translateX(1vw);
  -ms-transform: translateX(1vw);
  transform: translateX(1vw);
  transition: opacity 0.4s var(--transition), transform 0.4s var(--transition),
    -webkit-transform 0.4s var(--transition);
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s;
}

[data-sal|="header"].sal-animate .subhead {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

[data-sal|="fade-up"] {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-up"].sal-animate {
  opacity: 1;
  transform: translateY(0);
}

/* loader */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #FF1D00 #FF1D00;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes open-nav {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes close-nav {
  0% {
    opacity: 100;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}
